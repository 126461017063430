@font-face{
	font-family: OpenSans-light;
	src: url(../fonts/OpenSans-Light.ttf);
}

@font-face{
	font-family: OpenSans-bold;
	src: url(../fonts/OpenSans-Bold.ttf);
}

@font-face {
	font-family: SourceSansPro-regular;
	src: url(../fonts/SourceSansPro-Regular.ttf);
}

@font-face{
	font-family: OpenSans-bold;
	src: url(../fonts/OpenSans-Bold.ttf);
}

@font-face{
	font-family: OpenSans-lightitalic;
	src: url(../fonts/OpenSans-LightItalic.ttf);
}

*{
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

header{
	width: 100%;
	position: fixed;
	z-index: 100;
}

#logotipo img{
	width: 10%;
	margin-top: 35px;
	margin-left: 60px;
	position: absolute;
}

#logotipo h2{
	position: absolute;
	margin-left: 25%;
	margin-top: 5%;
	font-family: OpenSans-bold;
	color: #2c3e50;
}

#logotipo h3{
	position: absolute;
	font-size: 25px;
	margin-left: 36%;
	margin-top: 10%;
	font-family: OpenSans-light;
	color: #2c3e50;
}

/* INICIO DEL MENU */

.menu-bar{
	display: none;
} 

.derecha{
	margin-left: 5px;
}

nav{
	float: right;
	z-index: 1000;
	max-width: 1000px;
}

nav > ul{
	list-style: none;
	padding: 10px;
	background: rgba(253,254,254,0.8);
}

nav > ul > li{
	color: #2C3E50;
	display: inline-block;
	font-family: OpenSans-light;
	font-size: 100%;
	position: relative;
}

nav > ul > li > a {
	padding: 0px 30px;
	color: #2C3E50;	
	display: block;
	text-decoration: none;
}

nav > ul > li:hover > a{
	text-decoration: none;
	color: #ccc;
}

nav ul li.bt{
	display: none;
}

nav ul li .children{
	text-align: center;
	display: none;
	position: absolute;
	background:  rgb(213,216,220) ;
	z-index: 1000;
	width: 115%;
}

nav ul li .pa{
	margin-left: -10px;
}

nav ul li .pro{
	margin-left: -10px;
}

nav ul li:hover .children{
	display: none;
}

nav ul li .children li{
	display: block;
	overflow: hidden;
	border-bottom: 1px solid rgba(255,255,255,.5);
	margin-left: 2px;

}

nav ul li .children li a{
	display: block;
	text-decoration: none;
	color:#2C3E50;
	padding: 8px;

}

nav ul li .caret {
	position: relative;
	top: 3px;
	margin-left: 10px;
	margin-right: 0px;
}


/* FIN DEL MENU*/ 

@media (max-width: 600px){
	nav{
		text-align: center;
		width: 100%;
		right: 100%;
		margin: 0;
		position: fixed;
		overflow: scroll;
		background: #fff;
	}

	nav ul li {
		display: block;
		border-bottom: 1px solid rgba(213,216,220,1);
		float: none;
		padding: 20px;
	}
	
	nav ul li a{
		display: block;
	}

	nav ul li.bt{
		display: block;
	}

	nav ul li:hover .children{
		display: none;
	}

	nav ul li .children {
		width: 125%;
		position: relative;
		text-align: center;
		margin-left: -30px;
		margin-top: 20px;
	}


	.menu-bar{
		display: block;
		width: 100%;
		background: rgba(253,254,254,0.8);
	}

	.menu-bar .bt-menu{
		z-index: 1000%;
		float: right;
		padding: 20px;
		padding-left: 2%;
		padding-bottom: 1%;
		display: block;
		overflow: hidden;
		/*background: rgba(253,254,254,0.8);*/
		text-decoration: none;
		font-family: OpenSans-regular;
		font-size: 200%;
		color:#2C3E50;
			-box-sizing:border-box;
			-moz-box-sizing:border-box;
			-webkit-box-sizing:border-box;
	}
}

@media(max-width: 693px){
	
	
	#logotipo img{
		width: 15%;
		margin-top: 5px;
		margin-bottom: 50%;
		margin-left: 5px;
	}
}


@media (max-width: 1024px){

	.conte img{
		width: 100%;
	}

	#logotipo h2{
		margin-top: 50%;
		margin-left: 0;
	}

	#logotipo h3{
		text-align: justify;		
		margin-top: 90%;
		margin-left: 0;
	}

	.contenedoor .histori{
		margin-top: 65%;
	}
	
	.contenedoor .histori img, .contenedoor .desprecia img{
		margin: 0;
		width: 100%;
	}

	.contenedoor .histori .contehisto, .contenedoor .desprecia .contedespre{
		padding-left: 0%;
		margin-top: 5%;
		text-align: center;
		width: 100%;
		color: #566573;
	}

	button{
		margin-left: 0;
	}	

	.contenedoor .contenedor .padre .hijo{
		width: 100%;

	}
	
	.contenedoor .mail .caja{
		width: 100%;
		margin-left: 0%;
	}

	.contenedoor .mail img{
		margin-top: -15%;
	}

	.int-footer{
		margin-top: 22%;
	}

	.contenedoor .quiebre img{
		margin-top: 10%;
		margin-left: 45%;
	}

	.contenedoor .quiebre .texto p{
		margin-left: 5%;
		width: 90%;
	}

	.contenedoor .mail .caja ul li.correo{
		font-size: 75%;
	}

		.conte img{
	width: 100%;
	}

	.contenedor{
		margin: 0 auto;
		padding: 3%;
	}

	.contenedoor section .contenedor{
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}

	.contenedoor .contenedor .padre .hijo ul, .contenedoor .contenedor .padre .hijo h2{
		text-align: center;
	}

	.contenedoor .contenedor .padre .hijo{
		text-align: center;
	}



	.padre .hijo{
		width: 49.5%;
		vertical-align: top;
		padding: 0 1em;
		display: inline-block;
		margin-left: -0.26em;
		color: #566573;
		font-family: OpenSans-light;
	}

	.primero{
		margin-top: 5%;
	}
	ul{
		list-style: none;
	}

	/*TESTIMONIO*/ 

	.contenedoor .quiebre img{
		margin-top: 5%;
		margin-bottom: 50px;
		margin-left: 50%;
	}

	.contenedoor .quiebre .texto p{
		width: 80%;
		font-size: 25px;
		line-height: 32px;
		font-family: OpenSans-lightitalic;
		margin-bottom: 24px;
		text-align: justify;
		margin-left: 15%;
		color: #566573;
	}

	.contenedoor .quiebre .texto p.nom{
		font-size: 15px;
		font-weight: bold;
		font-family: OpenSans-light;
	}
	/*FIN DEL TESTIMONIO*/

	/*MAILCHIP*/

	.contenedoor .mail img{
		width: 99%;
		margin-top: -26%;
		z-index: -1;
		position: absolute;
	}

	.contenedoor .mail .caja h1{
		font-size: 200%;
	}

	.contenedoor .mail .caja label{
		font-size: 150%;
	}	

	.contenedoor .mail .caja{
		width: 50%;
		top: 100%;
		margin-left: 25%;
		text-align: center;
		padding: 10px;
		color: #566573;
		font-family: OpenSans-light;
		z-index: 100;
	}

	

	input[type="submit"]{
		font-weight: bold; 
		background: #eb5c57;
		padding: 0.3em 0.7em;
		color: #fff;
		font-family: OpenSans-light;
		border: none;
		font-size: 1em;
		margin-top: 10px;
		width: 70%;
		cursor: pointer;
	}

	.contenedoor .mail .caja ul {
		font-size:170%;
		margin-left: -13%;
	}
	.contenedoor .mail .caja ul li{
		margin-left: 10%;
	}

	.contenedoor .mail .caja ul li.iconface{
		color: #4cb29d;
	}

	.contenedoor .mail .caja ul li.iconinsta{
		color:#444c5c;
	}

	.contenedoor .mail .caja ul li.icontw{
		color:  #444c5c;
	}

	.contenedoor .mail .caja ul li.iconwor{
		color:#4cb29d;
	}

	.contenedoor .mail .caja ul li.correo{
		margin-top: 25px;
		font-size:85%;
	}
	/*FIN DE MAILCHIP*/

	/*INICIO DEL FOOTER`*/

	.int-footer{
		margin-top:12%;
		font-family: OpenSans-Light;
		color: #566573;
		padding-bottom: 2%;
		text-align: center;
	}
	/*FIN DEL FOOTER*/
}

@media (min-width: 280px){
	.contenedoor .mail .caja{
		width: 100%;
		margin-left: 0%;
	}

	.contenedoor .mail img{
		margin-top: -16%;
	}

	.contenedoor .mail .caja ul li.correo{
		font-size: 70%;
	}

	.int-footer{
		margin-top: 20%; 
	}

	button{
		font-weight: bold; 
		background: #eb5c57;
		padding: 0.3em 0.7em;
		color: #fff;
		font-family: OpenSans-light;
		border: none;
		font-size: 1em;
	}
}

@media (min-width: 322px){

	#logotipo h3{
		margin-top: 75%;
	}

	.contenedoor .histori{
		margin-top: 45%;
	}

	.contenedoor .contenedor .padre .hijo{
		text-align: center;
	}
	.contenedoor .quiebre .texto p{
		width: 90%;
	}
}

@media (min-width: 455px){
	#logotipo h3{
		margin-top:70%; 
	}
	
	.contenedoor .histori{
		margin-top: 40%;
	}

	.contenedoor .quiebre .texto p{
		width: 90%;
	}
}

@media(min-width: 500px){
	
	#logotipo h2{
		margin-top: 48%;
		width: 100%;
		margin-left: 0%;
	}

	#logotipo h3{
		margin-left: 0;
		margin-top: 65%;
	}

	.contenedoor .histori{
		margin-top: 35%;
	}

	.contenedoor .histori img, .contenedoor .desprecia img{
		width: 100%;
		margin-left: 0%;
	}

	.contenedoor .histori .contehisto, .contenedoor .desprecia .contedespre{
		margin-top: 5%;
		width: 100%;
		margin-left: 0%;
		padding-left: 0%;
	}

	.contenedoor .quiebre .texto p{
		width: 80%;
	}
}

@media(min-width: 556px){

	#logotipo h3{
		margin-top: 55%;
	}

	.contenedoor .histori{
		margin-top: 25%;
	}
}

@media(min-width: 670px){
	.conte img{
	width: 100%;
	}

	.contenedor{
		margin: 0 auto;
		padding: 3%;
	}

	.contenedoor section .contenedor{
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}

	.contenedoor .contenedor .padre .hijo ul, .contenedoor .contenedor .padre .hijo h2{
		text-align: center;
	}

	.contenedoor .contenedor .padre .hijo{
		text-align: center;
	}



	.padre .hijo{
		width: 49.5%;
		vertical-align: top;
		padding: 0 1em;
		display: inline-block;
		margin-left: -0.26em;
		color: #566573;
		font-family: OpenSans-light;
	}

	.primero{
		margin-top: 5%;
	}
	ul{
		list-style: none;
	}


	/*PUBLICIDAD DEL HISTORI*/
	.contenedoor .histori{
		margin-top: 8%;
	}

	.contenedoor .histori img{
		width: 50%;
	}

	.contenedoor .histori .contehisto{
		float: right;
		margin-top: -23%;
		font-family: OpenSans-light;
		width: 50%;
	}

	.contenedoor .histori .contehisto h1{
		color: #566573;
		text-align: center;
	}

	.contenedoor .histori .contehisto h2{
		color: #566573;
		text-align: center;
		font-size: 150%;
	}

	.contenedoor .histori .contehisto .descripcion{
		color: #566573;
		text-align: center;
		font-size: 100%;
	}

	button{
		font-weight: bold; 
		margin-left: 40%;
		background: #eb5c57;
		padding: 0.3em 0.7em;
		color: #fff;
		font-family: OpenSans-light;
		border: none;
		font-size: 1em;
	}

	button:hover{
		background: #4cb29d;
	}

	input[type="submit"]:hover{
		background: #4cb29d;
	}
	/*FIN DE LA PUBLICIDAD DEL HISTORI*/

	/*INICIO DE LA PUBLICIDAD DESPRECIA FÁCIL*/
	.contenedoor .desprecia{
		margin-top: 5%;
	}

	.contenedoor .desprecia img{
		width: 50%;
		margin-left: 50%;
	}

	.contenedoor .desprecia .contedespre{
		float: left;
		margin-top: -23%;
		font-family: OpenSans-light;
		width: 45%;
		padding-left: 45px;
	}

	.contenedoor .desprecia .contedespre h1{
		color: #566573;
		text-align: center;
	}

	.contenedoor .desprecia .contedespre h2{
		color: #566573;
		text-align: center;
		font-size: 150%;
	}

	.contenedoor .desprecia .contedespre .descripcion{
		color: #566573;
		text-align: center;
		font-size: 100%;
	}
	/*FIN DE LA PUBLICIDAD DESPRECIA FÁCIL

	/*TESTIMONIO*/ 

	.contenedoor .quiebre img{
		margin-top: 5%;
		margin-bottom: 50px;
		margin-left: 50%;
	}

	.contenedoor .quiebre .texto p{
		width: 80%;
		font-size: 25px;
		line-height: 32px;
		font-family: OpenSans-lightitalic;
		margin-bottom: 24px;
		text-align: justify;
		margin-left: 15%;
		color: #566573;
	}

	.contenedoor .quiebre .texto p.nom{
		font-size: 15px;
		font-weight: bold;
		font-family: OpenSans-light;
	}
	/*FIN DEL TESTIMONIO*/

	/*MAILCHIP*/

	.contenedoor .mail img{
		width: 99%;
		margin-top: -26%;
		z-index: -1;
		position: absolute;
	}

	.contenedoor .mail .caja h1{
		font-size: 200%;
	}

	.contenedoor .mail .caja label{
		font-size: 150%;
	}	

	.contenedoor .mail .caja{
		width: 50%;
		top: 100%;
		margin-left: 25%;
		text-align: center;
		padding: 10px;
		color: #566573;
		font-family: OpenSans-light;
		z-index: 100;
	}

	input[type="submit"]{
		font-weight: bold; 
		background: #eb5c57;
		padding: 0.3em 0.7em;
		color: #fff;
		font-family: OpenSans-light;
		border: none;
		font-size: 1em;
		margin-top: 10px;
		width: 70%;
		cursor: pointer;
	}

	.contenedoor .mail .caja ul {
		font-size:170%;
		margin-left: -13%;
	}
	.contenedoor .mail .caja ul li{
		margin-left: 10%;
	}

	.contenedoor .mail .caja ul li.iconface{
		color: #4cb29d;
	}

	.contenedoor .mail .caja ul li.iconinsta{
		color:#444c5c;
	}

	.contenedoor .mail .caja ul li.icontw{
		color:  #444c5c;
	}

	.contenedoor .mail .caja ul li.iconwor{
		color:#4cb29d;
	}

	.contenedoor .mail .caja ul li.correo{
		margin-top: 25px;
		font-size:85%;
	}
	/*FIN DE MAILCHIP*/

	/*INICIO DEL FOOTER`*/

	.int-footer{
		margin-top:12%;
		font-family: OpenSans-Light;
		color: #566573;
		padding-bottom: 2%;
		text-align: center;
	}
	/*FIN DEL FOOTER*/
}

@media (min-width: 670px){
	.contenedoor .histori{
		margin-top: 20%;
	}
}

@media(min-width: 696px){
	#logotipo h2{
		margin-top: 7%;
		margin-left: 20%;
	}

	#logotipo h3{
		margin-top: 12%;
		margin-left: 40%;
	}

	.contenedoor .histori{
		margin-top: 10%;
	}

	.contenedoor .contenedor .padre .hijo{
		width: 50%;
	}

	.contenedoor .histori .contehisto, .contenedoor .desprecia .contedespre{
		text-align: center;
	}

	.contenedoor .histori .contehisto{
		margin-top: -210px;
	}

	.contenedoor .desprecia .contedespre{
		margin-top: -230px;
	}

	.contenedoor .quiebre img{
		margin-top: 10%;
		margin-left: 0%;
	}



	button {
		margin-left: 0%;
	}
}

@media(min-width: 808px){
	.contenedoor .quiebre img{
		margin-left: 50%;
	}
}

@media (min-width: 900px){

	#logotipo h3{
		margin-left: 35%;
	}
}

@media (min-width: 1000px){

	#logotipo h2{
		margin-left: 25%;
	}

	#logotipo h3{
		margin-left: 38.5%;
	}
}

	
